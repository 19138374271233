import React from 'react';
import '../App.css'

const Footer = () => {

  return (
    <footer>
      <div className="container">
        <div className="row" style={{paddingTop: "50px", paddingBottom: "50px"}}>
          <div className="col">
            <h4>About</h4>
            <ul className='list-unstyled'>
              <li>
                <a href='/aboutUs'>About Us</a>
              </li>
              <li>Our Team</li>
              <li>Testimonials</li>
              <li>Contact Us</li>
            </ul>
          </div>
          <div className="col">
            <h4>Products</h4>
            <ul className='list-unstyled'>
              <li>Student Information System</li>
              <li>Learning Management System</li>
              <li>Tuition Management</li>
              <li>Parent Alert</li>
              <li>School Site</li>
            </ul>
          </div>
          <div className="col">
            <h4>Services</h4>
            <ul className='list-unstyled'>
              <li>Service 1</li>
              <li>Service 2</li>
              <li>Service 3</li>
              <li>Service 4</li>
            </ul>
          </div>
        </div>
        <span className="text-muted">School Information System &copy; {new Date().getFullYear()}</span>
      </div>
    </footer>
  );
};

export default Footer;