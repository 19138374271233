import React from 'react';
import Card from 'react-bootstrap/Card';
import CustomNavbar from './NavBar';
import Footer from './Footer';

const Team = () => {
  const teamMembers = [
    {
      name: 'Raymond Arthur',
      position: 'CEO',
      bio: 'Raymond Arthur is the CEO of our company. With his extensive experience in the education sector, he brings strong leadership and strategic vision to our team.',
      image: 'john-doe.jpg', // Add the image file name or path
    },
    {
      name: 'Isaac Kojo Yankson',
      position: 'CTO',
      bio: 'Isaac Kojo Yankson is our Chief Technology Officer. He oversees the development of our educational platform, ensuring innovation and technological excellence.',
      image: 'team_images/isaac_kojo_yankson.jpeg', // Add the image file name or path
    },
    {
      name: 'Michael Johnson',
      position: 'Head of Operations',
      bio: 'Michael Johnson manages the day-to-day operations of our organization. His expertise in operational efficiency drives our success in serving schools across Africa.',
      image: 'michael-johnson.jpg', // Add the image file name or path
    },
    // Add more team members as needed
  ];

  return (
    <div>
      <CustomNavbar />
      <div style={{ paddingTop: "50px", paddingBottom: "50px", backgroundColor: "#f4f4f4" }}>
          <div className="container">
            <div className="row">
            <h1 style={{ textAlign: "center" }}>Our Team</h1>
            </div>
          </div>
        </div>

        <div style={{ paddingTop: "50px", paddingBottom: "50px"}}>
          <div className="container">
            <div className="row">
              {teamMembers.map((member, index) => (
                <div className="col-lg-4 col-md-6" key={index}>
                  <Card>
                    <Card.Img variant="top" src={member.image} alt={member.name} />
                    <Card.Body>
                      <Card.Title>{member.name}</Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">{member.position}</Card.Subtitle>
                      <Card.Text>{member.bio}</Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              ))}
            </div>
          </div>
        </div>
       
        <Footer />
    </div>
    
  );
};

export default Team;
