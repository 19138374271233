import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AboutUs from './components/AboutUs';
import Team from './components/team';
import IndexPage from './components/IndexPage';
import SchoolSite from './components/SchoolSite';
import StudentInformationSystem from './components/StudentInformationSystem';
import TuitionManagement from './components/TuitionManagement';
import LearningManagement from './components/LearningManagement';
import ParentAlert from './components/ParentAlert';
import SchoolHome from './components/SchoolHome';
import SchoolUserLogin from './components/schoolUserLogin';
import ParentPage from './components/ParentPage';
import Profile from './components/Profile';
import Error404 from './components/Error404';
import SearchForm from './components/SearchSchool';


const App = () => {
  return (
    <div>
      <Router>
        <Routes>
          {/* Other routes */}
          <Route path="/" element={<IndexPage />} /> {/* Set the index page */}
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/team" element={<Team />} />
          <Route path="/school-site" element={<SchoolSite />} />      
          <Route path="/student-information-system" element={<StudentInformationSystem />} />
          <Route path="/tuition-management" element={<TuitionManagement />} />
          <Route path="/learning-management-system" element={<LearningManagement />} />
          <Route path="/parent-alert" element={<ParentAlert />} />
          <Route path="/:schoolSlug" element={<SchoolHome />} />
          <Route path="/:schoolSlug/:userType/login" element={<SchoolUserLogin />} />
          <Route path="/:schoolSlug/parent" element={<ParentPage />} />
          <Route path="/:schoolSlug/profile" element={<Profile />} />
          <Route path="/search-school" element={<SearchForm />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
