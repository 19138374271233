import React from 'react';
import CustomNavbar from './NavBar';
import Footer from './Footer';

const AboutUs = () => {
  return (
    <div>
      <CustomNavbar />
      <div style={{ paddingTop: "50px", paddingBottom: "50px", backgroundColor: "#f4f4f4" }}>
        <div className="container">
          <div className="row">
            <h1 style={{ textAlign: "center" }}>About Us</h1>
          </div>
        </div>
      </div>


      <div style={{ paddingTop: "50px", paddingBottom: "50px" }}>
        <div className="container">
          <div className="row">
            <h2 style={{ textAlign: "center" }}>Mission</h2>
            <p>
              ASIS is a pioneering educational platform designed specifically to cater to the needs of schools in Africa. With a focus on the diverse curricula followed by educational institutions across the continent, ASIS aims to streamline administrative processes, enhance communication channels, and promote efficient management of academic resources.
            </p>
            <p>
              ASIS is committed to bridging the gap in educational technology and providing a reliable, user-friendly solution tailored to the unique needs of African schools. By streamlining administrative tasks, enhancing communication, and promoting effective management of academic resources, ASIS empowers educators, students, and parents to thrive in the dynamic educational landscape of Africa.
            </p>
          </div>
        </div>
      </div>
      <div style={{ paddingTop: "50px", paddingBottom: "50px", backgroundColor: "#f4f4f4" }}>
        <div className='container'>
          <h2 style={{ textAlign: "center" }}>Key Features of ASIS</h2>

          <ul>
            <li>Customized Curriculum Management: ASIS recognizes the unique curricula adopted by schools in Africa, including national, regional, and international frameworks. The system allows schools to input their specific curriculum requirements, ensuring accurate tracking of subjects, syllabi, and learning outcomes.</li>
            <li>Student Information and Performance Tracking: ASIS provides comprehensive student profiles, allowing administrators and educators to maintain detailed records of individual students. It enables tracking academic progress, attendance, disciplinary actions, and extracurricular activities, enabling holistic student development.</li>
            <li>Teacher and Staff Management: ASIS simplifies teacher and staff management by offering a centralized database for personnel information, including qualifications, professional development, and contact details. This facilitates efficient scheduling, resource allocation, and communication between staff members.</li>
            <li>Attendance and Timetable Management: The system enables schools to manage attendance records, monitor punctuality, and generate reports for individual students or entire classes. Additionally, ASIS provides a flexible timetable management module that automates scheduling and facilitates adjustments when necessary.</li>
            <li>Online Gradebook and Assessment Tools: ASIS features a digital gradebook that allows teachers to input, calculate, and analyze student grades easily. The platform also supports various assessment methods, such as quizzes, tests, and assignments, providing educators with a range of tools to evaluate student performance effectively.</li>
            <li>Communication and Collaboration: ASIS incorporates communication channels for seamless interaction among administrators, teachers, students, and parents. It includes features such as direct messaging, announcements, and discussion forums, fostering effective collaboration and engagement within the school community.</li>
            <li>Resource and Material Repository: ASIS provides a centralized repository for educational resources, including lesson plans, textbooks, multimedia materials, and reference documents. This feature supports teachers in accessing relevant resources and sharing materials across the school.</li>
            <li>Reporting and Analytics: ASIS generates comprehensive reports and analytics, offering valuable insights into various aspects of school operations. Administrators can review data on attendance, grades, teacher performance, and student progress, enabling informed decision-making and evidence-based planning.</li>
            <li>Secure and Scalable: ASIS ensures the security and privacy of school data through robust encryption and access control measures. The system is scalable, accommodating schools of varying sizes and supporting future growth and technological advancements.</li>
          </ul>

        </div>

      </div>
      <Footer />
    </div>


  );
};

export default AboutUs;
