import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useParams } from 'react-router-dom';
import SchoolNavbar from './SchoolNavBar';
import { sentenceChecker } from './utils';


/**
 * Renders a component that displays information about a school.
 *
 * @return {JSX.Element} A component displaying school information.
 */
const SchoolHome = () => {

  const { schoolSlug } = useParams();
  const [schoolData, setSchoolData] = useState([]);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get(`${baseUrl}/api/schools/${schoolSlug}/`)
      .then(response => {
        setSchoolData(response.data);
      })
      .catch(error => {
        setError(error);
      });
  }, [baseUrl, schoolSlug]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!schoolData) {
    return <div>Loading...</div>;
  }

  const schoolName = schoolData.name;
  // Check if schoolName is defined before passing it to sentenceChecker
  const checkedSchoolName = schoolName ? sentenceChecker({ sentence: schoolName }) : '';



  return (

    <div>
      {/* <NavBar /> */}

      <SchoolNavbar schoolSlug={schoolSlug} schoolName={checkedSchoolName} />

      <div style={{ height: "50vh", paddingTop: "50px", paddingBottom: "50px", backgroundColor: "#f4f4f4" }}>
        <div className="container">
          <div className="row">
            <h1 style={{ textAlign: "center" }}>{schoolData.name}</h1>
          </div>
        </div>
      </div>


      <div style={{ paddingTop: "50px", paddingBottom: "50px" }}>
        <div className="container">
          <div className="row">
            <h2 style={{ textAlign: "center" }}>Blog</h2>

            {/* something goes here */}
          </div>
        </div>
      </div>

    </div>


  );
};

export default SchoolHome;
