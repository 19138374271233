import React from 'react';
import '../styles.css';

const SidebarItem = ({ item, onItemClick }) => {
  const handleClick = () => {
    onItemClick(item);
  };

  return (
    <div className="sidebar-item" onClick={handleClick}>
      {/* <img className="sidebar-item-image" src={item.image} alt={item.name} /> */}
      <span className="sidebar-item-name">{item.name}</span>
    </div>
  );
};

const SchoolSidebar = ({ onItemClick, sidebarItems}) => {
  

  return (
    <div className="school-sidebar">
      {sidebarItems.map((item, index) => (
        <SidebarItem key={index} item={item} onItemClick={onItemClick} />
      ))}
    </div>
  );
};

export default SchoolSidebar;
