import React from 'react';
import CustomNavbar from './NavBar';
import Footer from './Footer';

const ParentAlert = () => {
  return (
    <div >
      <CustomNavbar />
      <div style={{ paddingTop: "50px", paddingBottom: "50px", backgroundColor: "#f4f4f4" }}>
        <div className="container">
          <div className="row">
            <h1 style={{ textAlign: "center" }}>Parent Alert</h1>
          </div>
        </div>
      </div>


      <div className="container">
        <div className="row" style={{ height: "500px", textAlign: "center" }}>
          <div className="col mt-5">
            {/* Content for first row */}
            <h1>We Got You Covered</h1>
            <h3>For school administrators, teachers, students and families. </h3>
            <button className="btn btn-custom mt-5">Watch Our Story</button>
          </div>
        </div>
      </div>


      <div style={{ paddingTop: "50px", paddingBottom: "50px", backgroundColor: "#f4f4f4" }}>
        <div className='container' style={{ paddingBottom: "50px" }}>
          <div className="row">
            <h1 className="mt-5" style={{ textAlign: "center" }}>A Message for Families</h1>
            <div className="col">
              {/* Content for first column */}

              <p className='mt-5'>
                We aim to enhance the educational journey for both you and your students by ensuring a strong connection between you and the school, as well as keeping you informed about your students' school life, extracurricular activities, and academic advancement.
              </p>
            </div>

          </div>
        </div>

      </div>
      <Footer />
    </div>
  );
};

export default ParentAlert;