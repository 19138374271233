// Error404.js
import React from 'react';
import CustomNavbar from './NavBar';
import Footer from './Footer';

const Error404 = () => {
  return (
    <div>
        <CustomNavbar />
        <div className='container'>
            <div className='row'>
                <div className='col'>
                    <h1>404 - Page Not Found</h1>
                    <p>The requested page does not exist.</p>
                </div>
            </div>
        </div>
      <Footer />
    </div>
  );
};

export default Error404;
