// utils.js
export const sentenceChecker = ({ sentence }) => {
    const words = sentence.split(' ');
  
    if (words.length > 3) {
      const firstThreeWords = words.slice(0, 3).join(' ');
      return firstThreeWords;
    } else {
      return sentence;
    }
  };