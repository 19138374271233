import React, { useState, useEffect } from 'react';
import axios from "axios";
import CustomNavbar from './NavBar';
import Footer from './Footer';

const SearchForm = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [schoolsData, setSchoolsData] = useState([]);
  const [error, setError] = useState(null); // New error state
  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    // Fetch school data
    try {
      axios.get(`${baseUrl}/api/schools/`).then((response) => {
        setSchoolsData(response.data);
      });
    } catch (error) {
      console.error(error);
      console.log(error.response.data.error);
      setError(error.response.data.error); // Set the error message
    }


  }, [baseUrl]);
  console.log(schoolsData)

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleInputChange = (event) => {
    const searchValue = event.target.value;
    setSearchTerm(searchValue);

    // Filter schools based on the search term
    const filteredSchools = schoolsData.filter((school) =>
      school.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setSuggestions(filteredSchools);
  };

  const handleSchoolClick = (schoolSlug) => {
    // Implement your logic to navigate to the school's page based on the `schoolSlug`.
    // You can use React Router, window.location, or any other navigation method.
    window.location.href = `/${schoolSlug}`;
    console.log(`Navigating to school with ID ${schoolSlug}`);
  };

  const renderSuggestions = () => {
    return suggestions.map((school) => (
      <div key={school.slug} onClick={() => handleSchoolClick(school.slug)}>
        {school.name}
      </div>
    ));
  };

  return (
    <div>
      <CustomNavbar />
      <div className='container'>
        <div className='row'>
          <div className='col'>
            <h1>Search School</h1>
            <input type="text" value={searchTerm} onChange={handleInputChange} />
            <div>{renderSuggestions()}</div>
            {/* Implement your logic for the send button */}

          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default SearchForm;