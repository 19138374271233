import React from 'react';
import CustomNavbar from './NavBar';
import Footer from './Footer';

const SchoolSite = () => {
  return (
    <div >
      <CustomNavbar />
        <div style={{ paddingTop: "50px", paddingBottom: "50px", backgroundColor: "#f4f4f4" }}>
          <div className="container">
            <div className="row">
            <h1 style={{ textAlign: "center" }}>School Site</h1>
            </div>
          </div>
        </div>

        
        <div className="container">
          <div className="row" style={{ paddingTop: "50px", paddingBottom: "50px"}}>
            <div className="col mt-5">
              {/* Content for first row */}
              <div style={{textAlign: "center"}}>
              <h1>We Got You Covered</h1>
              <h3>For school administrators</h3>
              </div>
              
              <p>
              We are excited to inform you that we will be creating a school website for you that encompasses all the functionalities of this platform. The best part is that your data will not need to pass through our systems. We prioritize the privacy and security of your information.
              </p>
              <p>
              Our team of skilled developers will work closely with you to understand your requirements and design a website tailored specifically for your school. We will ensure that the website includes all the necessary features and functionalities that you desire, such as:
              </p>
              <button className="btn btn-custom mt-5">Watch Our Story</button>
            </div>
          </div>
        </div>
        

        <div  style={{ paddingTop: "50px", paddingBottom: "50px", backgroundColor: "#f4f4f4" }}>
          <div className='container'>
              <div className="row">
                <h1 style={{ textAlign: "center" }}>Contact Us</h1>
                  <div className="col">
                    {/* Content for first column */}
                    
                    <p className='mt-5'>
                    We aim to enhance the educational journey for both you and your students by ensuring a strong connection between you and the school, as well as keeping you informed about your students' school life, extracurricular activities, and academic advancement.
                    </p>
                  </div>

              </div>
          </div>
          
        </div>
        <Footer />
    </div>
  );
};

export default SchoolSite;
