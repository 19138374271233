import React, { useState, useEffect } from 'react';
import SchoolNavbar from './SchoolNavBar';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { sentenceChecker } from './utils';
import SchoolSidebar from './SchoolSidebar';

const StudentPerformance = () => {
  return <div>Student Performance</div>;
};

const PaymentForm = ({ member }) => {
  const handlePaymentSubmit = event => {
    event.preventDefault();
    // Add payment submission logic here
  };
console.log(member)
  return (
    <form onSubmit={handlePaymentSubmit}>
      <p>{member.first_name} {member.last_name}</p>
      <div className='form-group'>
        <label htmlFor='amount'>Amount:</label>
        <input type='text' id='amount' name='amount' className='form-control' required />
      </div>
      <div className='form-group'>
        <label htmlFor='paymentMethod'>Payment Method:</label>
        <input type='text' id='paymentMethod' name='paymentMethod' className='form-control' required />
      </div>
      <button type='submit' className='btn btn-primary'>
        Submit
      </button>
    </form>
  );
};

const ParentPage = () => {
  const { schoolSlug } = useParams();
  const [schoolData, setSchoolData] = useState([]);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [error, setError] = useState(null);
  const [parentStudents, setParentStudents] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [showPaymentForm, setShowPaymentForm] = useState(false);


  const StudentFees = ({ parentStudents }) => {
    console.log(parentStudents);
  
    return (
      <div>
        {parentStudents.map((member, index) => (
          <div className='row' key={index}>
            <div className='col-lg-6'>
              <h4>
                {member.student.first_name} {member.student.last_name}
              </h4>
              <p>
                {member.student.first_name} Owing: {member.student.fees}
              </p>
            </div>
            <div className='col-lg-6'>
              <button
                className='btn btn-primary'
                onClick={() => {
                  setSelectedStudent(member.student);
                  setShowPaymentForm(true);
                }}
              >
                Pay {member.student.first_name}'s Fees
              </button>
            </div>
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    axios
      .get(`${baseUrl}/api/schools/${schoolSlug}/`)
      .then(response => {
        setSchoolData(response.data);
      })
      .catch(error => {
        setError(error);
      });

    axios
      .get(`${baseUrl}/api/school/user/me/`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then(response => {
        const id = response.data.id;
        axios
          .get(`${baseUrl}/api/get-parent-students/${id}/`)
          .then(response => {
            console.log(response);
            setParentStudents(response.data);
          })
          .catch(error => {
            setError(error);
          });
      })
      .catch(error => {
        setError(error);
      });
  }, [baseUrl, schoolSlug]);
  // console.log(parentStudents)

  const handleSidebarItemClick = item => {
    setShowPaymentForm(false);
    setSelectedItem(item);
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!schoolData) {
    return <div>Loading...</div>;
  }

  const schoolName = schoolData.name;
  const checkedSchoolName = schoolName ? sentenceChecker({ sentence: schoolName }) : '';

  const sidebarItems = [
    {
      name: 'Student Performance',
      image: 'john-doe.jpg',
      component: <StudentPerformance />,
    },
    {
      name: 'Student Attendance',
      image: 'jane-smith.jpg',
      component: <StudentPerformance />,
    },
    {
      name: 'Student Report',
      image: 'michael-johnson.jpg',
      component: <StudentPerformance />,
    },
    {
      name: 'Student Fees',
      image: 'michael-johnson.jpg',
      component: <StudentFees parentStudents={parentStudents} />,
    },
  ];

  if (showPaymentForm) {
    return (
      <div>
        <SchoolNavbar schoolSlug={schoolSlug} schoolName={checkedSchoolName} />
        <div className='row'>
          <div className='col-lg-2'>
            <SchoolSidebar onItemClick={handleSidebarItemClick} sidebarItems={sidebarItems} />

          </div>
          <div className='col-lg-10'>
            <h2>Payment Form</h2>
            <PaymentForm member={selectedStudent} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <SchoolNavbar schoolSlug={schoolSlug} schoolName={checkedSchoolName} />

      <div>
        <div className='row'>
          <div className='col-lg-2'>
            <SchoolSidebar onItemClick={handleSidebarItemClick} sidebarItems={sidebarItems} />
          </div>
          <div className='col-lg-10'>
            {selectedItem ? (
              <div>
                <h2>{selectedItem.name}</h2>
                {selectedItem.component}
              </div>
            ) : (
              <div className='row'>
                <h1>Parent Dashboard</h1>
                <h3>Parent Students</h3>
                <div className='row'>
                  <div className='col-lg-6'>
                    <h2>{parentStudents.length}</h2>
                    <h1>Student(s)</h1>
                  </div>
                  <div className='col-lg-6'>{/* TODO */}</div>
                </div>
                <div className='row'>
                  <div className='col-lg-6'>
                    <h2>{parentStudents.length}</h2>
                    <h1>Student(s)</h1>
                  </div>
                  <div className='col-lg-6'>{/* TODO */}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParentPage;
