import SchoolLogin from "./SchoolLogin";
import { useParams } from 'react-router-dom';

const SchoolUserLogin = () => {
    const { schoolSlug, userType } = useParams();
    return (
        <SchoolLogin schoolSlug={schoolSlug} userType={userType} />
    );
}

export default SchoolUserLogin;