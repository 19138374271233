import React, { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

const CustomNavbar = () => {
  const [isProductsDropdownOpen, setIsProductsDropdownOpen] = useState(false);
  const [isAboutDropdownOpen, setIsAboutDropdownOpen] = useState(false);

  const handleProductsToggle = () => {
    setIsProductsDropdownOpen(!isProductsDropdownOpen);
  };

  const handleAboutToggle = () => {
    setIsAboutDropdownOpen(!isAboutDropdownOpen);
  };

  return (
    <div className='container-fluid'>
      <Navbar bg="light" expand="lg">
      <Navbar.Brand href="/">
      <div style={{ fontSize: '2rem', fontWeight: '700', color: '#8AFF8A' }}>
      XmartSIS
      </div>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav style={{position: "absolute", right: "0px"}}>
          <NavDropdown
            title="Products"
            id="products-dropdown"
            show={isProductsDropdownOpen}
            onClick={handleProductsToggle}
          >
            <NavDropdown.Item href="/student-information-system">Student Information System</NavDropdown.Item>
            <NavDropdown.Item href="/learning-management-system">Learning Management System</NavDropdown.Item>
            <NavDropdown.Item href="/tuition-management">Tuition Management</NavDropdown.Item>
            <NavDropdown.Item href="/parent-alert">Parent Alert</NavDropdown.Item>
            <NavDropdown.Item href="/school-site">School Site</NavDropdown.Item>
            
          </NavDropdown>
          <NavDropdown 
            title="About"
            id="about-dropdown"
            show={isAboutDropdownOpen}
            onClick={handleAboutToggle}
          >
            <NavDropdown.Item href="/aboutUs">About Us</NavDropdown.Item>
            <NavDropdown.Item href="/team">Team</NavDropdown.Item>
          </NavDropdown>
          <Nav.Link href="/search-school">School Login</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
    </div>
    
  );
};

export default CustomNavbar;
