import React, { useState, useEffect } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';

const SchoolNavbar = ({ schoolSlug, schoolName }) => {
  const [isLoginDropdownOpen, setIsLoginDropdownOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLoginToggle = () => {
    setIsLoginDropdownOpen(!isLoginDropdownOpen);
  };

  const handleLogout = () => {
    // Remove the token from local storage
    localStorage.removeItem('token');

    // Perform any additional logout actions
    window.location.href = `/${schoolSlug}/`;

    // Update the login status state or redirect the user
  };

  return (
    <div className="container-fluid">
      <Navbar bg="light" expand="lg">
        <Navbar.Brand href={`/${schoolSlug}/`}>{schoolName}</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {isLoggedIn ? (
          // Render a different component when logged in
          <LoggedInNavbarContent schoolSlug={schoolSlug} handleLogout={handleLogout} />
        ) : (
          // Render the default navbar content
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav style={{ position: 'absolute', right: '10px' }}>
              <Nav.Link href="#">Blog</Nav.Link>
              <NavDropdown
                title="Login"
                id="login-dropdown"
                show={isLoginDropdownOpen}
                onClick={handleLoginToggle}
              >
                <NavDropdown.Item href={`/${schoolSlug}/student/login`}>Student</NavDropdown.Item>
                <NavDropdown.Item href={`/${schoolSlug}/teacher/login`}>Teacher</NavDropdown.Item>
                <NavDropdown.Item href={`/${schoolSlug}/parent/login`}>Parent</NavDropdown.Item>
                <NavDropdown.Item href={`/${schoolSlug}/admin/login`}>Admin</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        )}
      </Navbar>
    </div>
  );
};

// Render the logged-in navbar content
const LoggedInNavbarContent = ({ schoolSlug, handleLogout }) => {
  return (
    <Navbar.Collapse id="loggedin-navbar-nav">
      {/* Add your custom content for the logged-in state */}
      <Nav style={{ position: 'absolute', right: '10px' }}>
        <Nav.Link href={`/${schoolSlug}/parent`}>Dashboard</Nav.Link>
        <Nav.Link href={`/${schoolSlug}/profile`}>Profile</Nav.Link>
        <Nav.Link href="#" onClick={handleLogout}>
          Logout
        </Nav.Link>
        <Nav.Link href="#">
          <FontAwesomeIcon icon={faBell} />
        </Nav.Link>
      </Nav>
    </Navbar.Collapse>
  );
};

export default SchoolNavbar;
