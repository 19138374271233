import React, { useState, useEffect } from 'react';
import axios from "axios";
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import SchoolNavbar from './SchoolNavBar';
import { sentenceChecker } from './utils';

const SchoolLogin = ({ schoolSlug, userType }) => {
  const [schoolData, setSchoolData] = useState([]);
  const [error, setError] = useState(null); // New error state
  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      // User is already logged in, redirect to home page
      window.location.href = `/${schoolSlug}`;
    } else {
      // Fetch school data
      axios.get(`${baseUrl}/api/schools/${schoolSlug}/`).then((response) => {
        setSchoolData(response.data);
      });
    }
  }, [baseUrl, schoolSlug]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const username = event.target.elements.formUsername.value;
    const password = event.target.elements.formPassword.value;

    try {
      // Make API request to authenticate the user
      const response = await axios.post(`${baseUrl}/api/users/login/`, {
        username,
        password
      });

      // Handle successful login
      const user_response = await axios.get(`${baseUrl}/api/school/user/me/`, {
        headers: { Authorization: `Bearer ${response.data.access}` }
      });
      console.log(user_response.data);
      if (user_response.data.role === userType && user_response.data.school === schoolData.id) {
        localStorage.setItem('token', response.data.access);
        window.location.href = `/${schoolSlug}/${userType}-dashboard`;
      } else {
        setError("You are not authorized to view this page");
      }
    } catch (error) {
      // Handle login error
      console.error(error);
      console.log(error.response.data.error);
      setError(error.response.data.error); // Set the error message
    }
  };

  const schoolName = schoolData.name;
  // Check if schoolName is defined before passing it to sentenceChecker
  const checkedSchoolName = schoolName ? sentenceChecker({ sentence: schoolName }) : '';

  return (
    <div>
      <SchoolNavbar schoolSlug={schoolSlug} schoolName={checkedSchoolName} />
      <div style={{ paddingTop: "50px", paddingBottom: "50px" }}>
        <Container>
          <div style={{ textAlign: "center" }}>
            <h1>{schoolData.name}</h1>
            <h2>{userType.charAt(0).toUpperCase() + userType.slice(1)} Login</h2>
            {error && <p className="error-message">{error}</p>} {/* Show error message if error is present */}
          </div>

          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formUsername">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="text" placeholder="Enter Username" />
            </Form.Group>

            <Form.Group controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" />
            </Form.Group>

            <Button variant="primary" type="submit">
              Login
            </Button>
          </Form>
        </Container>
      </div>
    </div>
  );
};

export default SchoolLogin;
