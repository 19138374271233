import React, { useState, useEffect } from "react";
import SchoolNavbar from './SchoolNavBar';
import axios from "axios";
import { useParams } from "react-router-dom";
import { sentenceChecker } from "./utils";




const Profile = () => {
    const { schoolSlug } = useParams();
    const [schoolData, setSchoolData] = useState([]);
    const [userData, setUserData] = useState({
        username: '',
        first_name: '',
        last_name: '',
        email: '',
        profilePic: '', // Add profilePic property for storing the profile picture URL
    });
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [error, setError] = useState(null);
    const [editMode, setEditMode] = useState(false);


    const handleEditClick = () => {
        setEditMode(true);
      };


      const EditForm = () => {
        return (
            <form>
            <div className="form-group">
              <label htmlFor="username">Username:</label>
              <input type="text" className="form-control" id="username" value={userData.username} />
            </div>
            <div className="form-group">
              <label htmlFor="firstName">First Name:</label>
              <input type="text" className="form-control" id="firstName" value={userData.first_name} />
            </div>
            <div className="form-group">
              <label htmlFor="lastName">Last Name:</label>
              <input type="text" className="form-control" id="lastName" value={userData.last_name} />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input type="text" className="form-control" id="email" value={userData.email} />
            </div>
            <button type="submit" className="btn btn-primary">Save</button>
          </form>
        );
      };


    useEffect(() => {
        axios
            .get(`${baseUrl}/api/schools/${schoolSlug}/`)
            .then(response => {
                setSchoolData(response.data);
            })
            .catch(error => {
                setError(error);
            });

        axios.get(`${baseUrl}/api/school/user/me/`, {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
        }).then(response => {
            setUserData(prevUserData => ({
                ...prevUserData,
                ...response.data,
                profilePic: response.data.profilePic // Assuming the API response contains a 'profilePic' property
            }));
        }).catch(error => {
            setError(error);
        });
    }, [baseUrl, schoolSlug]);

    console.log(userData.username);

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    if (!schoolData) {
        return <div>Loading...</div>;
    }

    const schoolName = schoolData.name;
    const checkedSchoolName = schoolName ? sentenceChecker({ sentence: schoolName }) : '';

    return (
        <div>
          <SchoolNavbar schoolSlug={schoolSlug} schoolName={checkedSchoolName} />
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>Profile</h1>
                {editMode ? (
                  <EditForm />
                ) : (
                  <>
                    <img src={userData.profilePic || '/default_images/profile-picture.png'}
                      alt="Profile"
                      height={'200px'}
                      width={'200px'}
                    />
                    <p>Username: {userData.username}</p>
                    <p>First Name: {userData.first_name}</p>
                    <p>Last Name: {userData.last_name}</p>
                    <p>Email: {userData.email}</p>
                    <p>School: {schoolData.name}</p>
                    <button onClick={handleEditClick} className="btn btn-primary">Edit Profile</button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    };


export default Profile;
