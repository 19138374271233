import React from 'react';
import '../styles.css';
import CustomNavbar from './NavBar';
import Footer from './Footer';


const Card = ({ member }) => (
  <div className="custom-card">
    <img src={member.image} alt={member.name} />
    <div className="card-content">
      <h3>{member.name}</h3>
      <p>{member.position}</p>
      <p>{member.bio}</p>
      <button className="btn-custom mt-5" href={member.url} target="_blank" rel="noopener noreferrer">Learn More</button>
      {/* <a href={member.url} target="_blank" rel="noopener noreferrer">
        Go to Website
      </a> */}
    </div>
  </div>
);

const IndexPage = () => {
  const products = [
    {
      name: 'School Information System',
      bio: 'Our state-of-the-art solutions enable seamless information flow between school administrators, teachers, and parents, automating processes for enhanced convenience and efficiency.',
      image: 'john-doe.jpg', // Add the image file name or path
      url: '/school-login',
    },
    {
      name: 'Learning Management System',
      bio: 'Isaac Kojo Yankson is our Chief Technology Officer. He oversees the development of our educational platform, ensuring innovation and technological excellence.',
      image: 'jane-smith.jpg', // Add the image file name or path
      url: '',
    },
    {
      name: 'Tuition Management',
      bio: 'Michael Johnson manages the day-to-day operations of our organization. His expertise in operational efficiency drives our success in serving schools across Africa.',
      image: 'michael-johnson.jpg', // Add the image file name or path
      url: '',
    },
    {
      name: 'Parent Alert',
      bio: 'We provide real-time notifications to parents as events occur.',
      image: 'michael-johnson.jpg', // Add the image file name or path
      url: '',
    },
    {
      name: 'School Site',
      bio: 'Let\'s create a comprehensive school website that provides essential tools while giving you complete control over your data.',
      image: 'michael-johnson.jpg', // Add the image file name or path
      url: '',
    },
    // Add more team members as needed
  ];
  return (
    <div >
        <CustomNavbar />  

        <div className="text-white py-5 md:py-6" style={{ paddingTop: "100px", paddingBottom: "100px", background: "linear-gradient(to right, #8AFF8A, #34D399)" }}>
            <div className="container">
              <div className="row">
                <div className="text-center">
                    <h1 className="display-1 mb-5" style={{ fontWeight: 800 }}>XmartSIS</h1>
                    <p className="fs-3 mb-5" style={{ fontWeight: 500 }}>Empowering Education, One Student at a Time</p>
                    <button 
                        className="btn btn-outline-light btn-lg px-5 py-2 fs-5 fw-bold rounded-pill" 
                        style={{ transition: "all 0.3s ease" }} 
                        onMouseOver={e => { e.currentTarget.style.backgroundColor = '#ffffff'; e.currentTarget.style.color = '#6B46C1'; }} 
                        onMouseOut={e => { e.currentTarget.style.backgroundColor = ''; e.currentTarget.style.color = ''; }}
                    >
                        Watch Our Story
                    </button>
                </div>
              </div>
              
            </div>

          </div>

      
        <div style={{ paddingTop: "50px", paddingBottom: "50px", backgroundColor: "#f4f4f4" }}>
          <div className="container">
            <div className="row">
            <h1 style={{ textAlign: "center" }}>Our Aim</h1>
              <div className="col-md-6 mt-5">
                {/* Content for first column */}
                
                <p>
                  At our core, we are dedicated to enhancing school management by leveraging service and technology. Our unwavering commitment lies in assisting schools, administrators, teachers, and families in overcoming their distinct challenges.
                </p>
              </div>
              <div className="col-md-6">
                {/* Content for second column */}
                {/* Add your content for the second column here */}
                <button className="btn-custom mt-5">Read Our Story</button>
              </div>
            </div>
          </div>
        </div>

        <div className='container' style={{paddingBottom: "50px"}}>

          <div className="row">
            {/* Content for first row */}
            <h1 className="mt-5" style={{ textAlign: "center" }}>Our Products</h1>
            {products.map((member, index) => (
            <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center" key={index}>
              <Card member={member} />
            </div>
          ))}

          </div>

        </div>
        

        <div  style={{ paddingTop: "50px", paddingBottom: "50px", backgroundColor: "#f4f4f4" }}>
          <div className='container'>
              <div className="row">
                <h1 style={{ textAlign: "center" }}>A Message for Families</h1>
                  <div className="col d-flex flex-column align-items-center">
                    {/* Content for first column */}
                    
                    <p className='mt-5'>
                    We aim to enhance the educational journey for both you and your students by ensuring a strong connection between you and the school, as well as keeping you informed about your students' school life, extracurricular activities, and academic advancement.
                    </p>
                    <button className="btn-custom card-btn mt-5">Read Our Story</button>
                  </div>

              </div>
          </div>
          
        </div>

              
        <Footer />
    </div>
  );
};

export default IndexPage;